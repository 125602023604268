import { subfix_domain } from '../api';

const get_packages = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/package/`, {
      params: { subfix_domain: '' },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_package = async (authAxios, formData) => {
  formData.append('subfix_domain', subfix_domain);
  try {
    const res = await authAxios.post(`/api/package/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_package = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/package/${id}/`, {
      subfix_domain,
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export { get_packages, create_package, delete_package };
