import React from 'react';
import './index.css';

export default function Footer() {
  const footerData = [
    {
      heading: 'RESERVATIONS',
      body: [
        {
          to: '',
          text: 'Start a Reservation',
        },
        {
          to: '',
          text: 'View/Modify/Cancel',
        },
        {
          to: '',
          text: 'Get a Receipt',
        },
        {
          to: '',
          text: 'Start a Moving Truck or Van Reservation',
        },
        {
          to: '',
          text: 'Short Term Car Rental',
        },
        {
          to: '',
          text: 'Subscribe with Enterprise',
        },
        {
          heading: 'ENTERPRISE PLUS®',
          body: [
            {
              to: '',
              text: 'Sign In',
            },
            {
              to: '',
              text: 'Learn More About Enterprise Plus®',
            },
            {
              to: '',
              text: 'Join Enterprise Plus®',
            },
          ],
        },
      ],
    },
    {
      heading: 'VEHICLES',
      body: [
        {
          to: '',
          text: 'Cars',
        },
        {
          to: '',
          text: 'SUVs',
        },
        {
          to: '',
          text: 'Trucks',
        },
        {
          to: '',
          text: 'Minivans & Vans',
        },
        {
          to: '',
          text: 'Moving Trucks & Vans',
        },
        {
          to: '',
          text: 'Exotic Cars',
        },
        {
          heading: 'SHARE',
          body: [
            {
              to: '',
              text: 'Enterprise CarShare',
            },
            {
              to: '',
              text: 'Commute with Enterprise',
            },
          ],
        },
      ],
    },
    {
      heading: 'PROMOTIONS',
      body: [
        {
          to: '',
          text: 'All Deals & Coupons',
        },
        {
          to: '',
          text: 'Sign Up For Email Specials',
        },
        {
          to: '',
          text: 'Partner Rewards Programs',
        },
        {
          heading: 'BUY',
          body: [
            {
              to: '',
              text: 'Enterprise Car Sales',
            },
          ],
        },
        {
          heading: 'CAR RENTAL LOCATIONS',
          body: [
            {
              to: '',
              text: 'U.S Car Rental Locations',
            },
            {
              to: '',
              text: 'International Car Rental Locations',
            },
            {
              to: '',
              text: 'Airport Car Rental Locations',
            },
          ],
        },
      ],
    },
    {
      heading: 'BUSINESS',
      body: [
        {
          to: '',
          text: 'Solutions for Business',
        },
        {
          to: '',
          text: 'Global Franchise Opportunities',
        },
        {
          to: '',
          text: 'General Aviation',
        },
        {
          to: '',
          text: 'Enterprise Truck Rental',
        },
        {
          to: '',
          text: 'Enterprise Fleet Management',
        },
        {
          to: '',
          text: 'Travel Administrator',
        },
        {
          to: '',
          text: 'Travel Advisor',
        },
        {
          to: '',
          text: 'Enterprise CarShare ',
        },
        {
          to: '',
          text: 'Commute with Enterprise',
        },
        {
          to: '',
          text: 'Entertainment & Production Rentals',
        },
      ],
    },
    {
      heading: 'CUSTOMER SERVICES',
      body: [
        {
          to: '',
          text: 'Help & FAQs',
        },
        {
          to: '',
          text: 'Contact Us',
        },
        {
          to: '',
          text: 'Customers with Disabilities',
        },
        {
          to: '',
          text: 'Site Map',
        },
        {
          to: '',
          text: 'Safety Recall Information',
        },
        {
          heading: 'INSPIRATION',
          body: [
            {
              to: '',
              text: 'Pursuits with Enterprise',
            },
            {
              to: '',
              text: 'Road Trip Ideas',
            },
          ],
        },
      ],
    },
    {
      heading: 'INTERNATIONAL WEBSITES',
      body: [
        {
          to: '',
          text: 'Enterprise Canada',
        },
        {
          to: '',
          text: 'Enterprise France',
        },
        {
          to: '',
          text: 'Enterprise Germany',
        },
        {
          to: '',
          text: 'Enterprise Ireland',
        },
        {
          to: '',
          text: 'Enterprise Spain',
        },
        {
          to: '',
          text: 'Enterprise UK',
        },
        {
          to: '',
          text: 'Other Enterprise Website',
        },
      ],
    },
    {
      heading: 'This Company',
      body: [
        {
          to: '',
          text: 'About Us',
        },
        {
          to: '',
          text: 'Total Mobility Solutions',
        },
        {
          to: '',
          text: 'Meet Our People',
        },
        {
          to: '',
          text: 'Supporting Those in Service',
        },
        {
          to: '',
          text: 'Community & Sustainability ',
        },
        {
          to: '',
          text: 'Enterprise Holdings®',
        },
        {
          to: '',
          text: 'Careers',
        },
        {
          to: '',
          text: 'Press',
        },
        {
          to: '',
          text: 'Affiliate Programs',
        },
        {
          to: '',
          text: 'Youth Sports Fundraising',
        },
      ],
    },
  ];

  return (
    <footer className='footer'>
      <div className='footer_heading'>RenoTahoeRentals</div>
      <div className='footer_sections'>
        {footerData.map((footer, key) => (
          <div key={key} className='footer_section_main footer_section'>
            <div className='footer_section_heading'>{footer.heading}</div>
            <div className='footer_section_body'>
              {footer.body.map((body, index) =>
                !body.heading ? (
                  <div key={index}>{body.text}</div>
                ) : (
                  <div key={index} style={{ marginTop: '1rem' }} className='footer_section'>
                    <div className='footer_section_heading'>{body.heading}</div>
                    <div className='footer_section_body'>
                      {body.body.map((body, index) => (
                        <div key={index}>{body.text}</div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='footer_buttons'>
        <div>
          <img src='/assets/imgs/appStore.png' alt='App Store' />
        </div>
        <div>
          <img src='/assets/imgs/playStore.png' alt='Play Store' />
        </div>
      </div>

      <div className='footer_footer'>
        <div className='footer_terms'>
          <div>Terms of Use Privacy Policy</div>
          <div>Cookie Policy</div>
          <div>Cookie Settings / AdChoices </div>
        </div>
        <div className='footer_copyright'>
          <div>© 2022 Enterprise Holdings, Inc.</div>
          <div>All Rights Reserved</div>
        </div>
      </div>
    </footer>
  );
}
