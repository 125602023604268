import { subfix_domain } from '../api';
// import axios from 'axios';

const get_user = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/customers/${id}/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_user = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/customers/customer-register/`, data);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_company_user = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/company-user/register/`, data);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_admins = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/company-user/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const check_company = async (authAxios, data) => {
  try {
    const res = await authAxios.get(`/api/auth/check-company/`, { params: data });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
  get_user,
  create_user,
  create_company_user,
  get_admins,
  check_company,
};
