import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '@mui/material/Modal';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

export default function PaymentModal({ open, onClose, onSubmit, price }) {
  const [errorMsg, setErrorMsg] = useState(false);
  const [toggleCvv, setToggleCvv] = useState(false);
  const { wrapperProps, getCardImageProps, getCardNumberProps } =
    usePaymentInputs();

  const [cardNumber, setCardNumber] = useState();
  const [cardCvv, setCardCvv] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [expiryYear, setExpiryYear] = useState();

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg(false);
    }, 6000);
  }, [errorMsg]);

  return (
    <Modal
      open={open}
      onClose={() => (
        onClose(),
        setCardNumber(''),
        setCardCvv(''),
        setExpiryDate(''),
        setExpiryYear('')
      )}
      aria-labelledby='conf_modal'
      aria-describedby='modal-modal-description'
    >
      <div className='paymentDetails' style={{ width: '50%' }}>
        <form
          action='#'
          onSubmit={(e) => {
            // handleConfirm(e);
            e.preventDefault();
            // EditModal();
            onSubmit({ cardNumber, cardCvv, expiryDate, expiryYear });
            onClose(true);
          }}
          className='card_details'
          style={{ margin: 0 }}
        >
          <div className='paymentDetails_heading_wrapper'>
            <div className='paymentDetails_heading'>Payment Details</div>

            <div className='paymentDetailMsgs'>{errorMsg && errorMsg}</div>
          </div>
          <div className='price'>
            <div className='price_label'>Price: $ {price}</div>
          </div>
          <div className='card_number'>
            <div className='card_number_header'>
              <div className='card_label'>
                Card Number <br />
                <span>Enter the 16-digit card number on the card</span>
              </div>
            </div>
            <PaymentInputsWrapper {...wrapperProps}>
              <svg {...getCardImageProps({ images })} />
              <input
                required
                defaultValue={cardNumber}
                {...getCardNumberProps({
                  onChange: (e) => setCardNumber(e.target.value),
                })}
              />
            </PaymentInputsWrapper>
          </div>
          <div className='cardRow cvv_number'>
            <div className='cardRow_text  cvv_number_text'>
              <div className='card_label'>
                CVV Number <br />
                <span>Enter 3 or 4 digit number on the card</span>
              </div>
            </div>
            <div className='cardRow_input cvv_number_input'>
              <input
                required
                defaultValue={cardCvv}
                onChange={(e) => setCardCvv(e.target.value)}
                maxLength={4}
                placeholder='CVV'
                type={toggleCvv ? 'password' : 'text'}
                name='cvv'
                id='cvv'
              />
              <svg
                className={`${!toggleCvv ? 'cvv_visible' : 'cvv_hidded'}`}
                onClick={() => setToggleCvv(!toggleCvv)}
                width='28'
                height='28'
                viewBox='0 0 28 28'
                fill='none'
              >
                <path
                  d='M3.50065 4.66667C3.50065 5.311 4.02299 5.83333 4.66732 5.83333C5.31165 5.83333 5.83398 5.311 5.83398 4.66667C5.83398 4.02233 5.31165 3.5 4.66732 3.5C4.02299 3.5 3.50065 4.02233 3.50065 4.66667Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M12.8346 4.66667C12.8346 5.311 13.357 5.83333 14.0013 5.83333C14.6456 5.83333 15.168 5.311 15.168 4.66667C15.168 4.02233 14.6456 3.5 14.0013 3.5C13.357 3.5 12.8346 4.02233 12.8346 4.66667Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M22.1667 4.66667C22.1667 5.311 22.689 5.83333 23.3333 5.83333C23.9777 5.83333 24.5 5.311 24.5 4.66667C24.5 4.02233 23.9777 3.5 23.3333 3.5C22.689 3.5 22.1667 4.02233 22.1667 4.66667Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M3.50065 14C3.50065 14.6443 4.02299 15.1667 4.66732 15.1667C5.31165 15.1667 5.83398 14.6443 5.83398 14C5.83398 13.3557 5.31165 12.8333 4.66732 12.8333C4.02299 12.8333 3.50065 13.3557 3.50065 14Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M12.8346 14C12.8346 14.6443 13.357 15.1667 14.0013 15.1667C14.6456 15.1667 15.168 14.6443 15.168 14C15.168 13.3557 14.6456 12.8333 14.0013 12.8333C13.357 12.8333 12.8346 13.3557 12.8346 14Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M22.1667 14C22.1667 14.6443 22.689 15.1667 23.3333 15.1667C23.9777 15.1667 24.5 14.6443 24.5 14C24.5 13.3557 23.9777 12.8333 23.3333 12.8333C22.689 12.8333 22.1667 13.3557 22.1667 14Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M3.50065 23.3333C3.50065 23.9777 4.02299 24.5 4.66732 24.5C5.31165 24.5 5.83398 23.9777 5.83398 23.3333C5.83398 22.689 5.31165 22.1667 4.66732 22.1667C4.02299 22.1667 3.50065 22.689 3.50065 23.3333Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M12.8346 23.3333C12.8346 23.9777 13.357 24.5 14.0013 24.5C14.6456 24.5 15.168 23.9777 15.168 23.3333C15.168 22.689 14.6456 22.1667 14.0013 22.1667C13.357 22.1667 12.8346 22.689 12.8346 23.3333Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
                <path
                  d='M22.1667 23.3333C22.1667 23.9777 22.689 24.5 23.3333 24.5C23.9777 24.5 24.5 23.9777 24.5 23.3333C24.5 22.689 23.9777 22.1667 23.3333 22.1667C22.689 22.1667 22.1667 22.689 22.1667 23.3333Z'
                  stroke='#BEBEBE'
                  strokeWidth='2'
                />
              </svg>
            </div>
          </div>
          <div className='cardRow expiry'>
            <div className='cardRow_text expiry_text'>
              <div className='card_label'>
                Expiry Date <br />
                <span>Enter the expiry date on the card</span>
              </div>
            </div>
            <div className='cardRow_input expiry_input'>
              <input
                required
                type='number'
                name='expiry_date'
                placeholder='MM'
                id='expiry_date'
                pattern='[0-9]*'
                defaultValue={expiryDate}
                onChange={(event) => {
                  if (event.target.value.length === 3) return false; //limits to 10 digit entry
                  setExpiryDate(event.target.value); //saving input to state
                }}
              />
              <div className='expiry_separator'></div>
              <input
                required
                type='number'
                name='expiry_year'
                placeholder='YYYY'
                id='expiry_year'
                pattern='[0-9]*'
                defaultValue={expiryYear}
                onChange={(event) => {
                  if (event.target.value.length === 5) return false; //limits to 10 digit entry
                  setExpiryYear(event.target.value); //saving input to state
                }}
              />
            </div>
          </div>

          <div className='confirmation_btn'>
            <button
              //   disabled={cardNumber !== '' &&cardCvv !== '' &&expiryDate !== '' &&expiryYear !== '' &&cardHolderName !== '' &&postalCode !== ''? false: true}
              type='submit'
            >
              Confirmation
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
