import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { AuthContextProvider } from './contexts/AuthContext';

import HomePage from './pages/HomePage';
import Register from './pages/Register';
import Login from './pages/Login';
import TermsConditions from './pages/TermsConditions';

export default function App() {
  // axios.defaults.baseURL = 'https://app.rtcar-be.tk';
  const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
  });

  console.log(process.env)
  console.log(process.env.REACT_APP_NODE_ENV)
  return (
    <StylesProvider generateClassName={generateClassName}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/register'
            element={
              <AuthContextProvider>
                <Register />
              </AuthContextProvider>
            }
          />
          <Route
            exact
            path='/register/:pack'
            element={
              <AuthContextProvider>
                <Register />
              </AuthContextProvider>
          }
          />
          <Route
            path='/login'
            element={
              <AuthContextProvider>
                <Login />
              </AuthContextProvider>
            }
          />
           <Route
            path='/login/:error'
            element={
              <AuthContextProvider>
                <Login />
              </AuthContextProvider>
            }
          />
          <Route
            path='/terms'
            element={
              <AuthContextProvider>
                <TermsConditions />
              </AuthContextProvider>
            }
          />
          <Route
            exact
            path='/'
            element={
              <AuthContextProvider>
                <HomePage />
              </AuthContextProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </StylesProvider>
  );
}
