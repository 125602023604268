import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import './index.css';

export default function NavBar({ loginPage }) {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      //   bg='dark'
      variant='dark'
      className='nav_bar'
    >
      {/* <Container> */}
      <Navbar.Brand className='logo'>
        <img src='/assets/imgs/logo.png' alt='logo' />
      </Navbar.Brand>
      {!loginPage && (
        <>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'></Nav>
            <Nav className='nav_buttons'>
              {/* <Link to='/'>About Us</Link>
              <Link to='/'>Contact Us</Link> */}
              {/* {!user && ( */}
              <Link to='/login' className='login_nav_btn'>
                Login
              </Link>
              {/* )} */}
            </Nav>
          </Navbar.Collapse>
        </>
      )}
      {/* </Container> */}
    </Navbar>
  );
}
