import React, { useState, useEffect, useContext } from 'react';
import Card from '../../Components/CardsWrapper/Card';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';
import { AuthContext } from '../../contexts/AuthContext';
import { pages } from '../../constants';
// import TimingsCard from '../../Components/TimingsCard';
import './index.css';

import { get_packages } from '../../API/package';

export default function HomePage({ ...props }) {
  const { authAxios } = useContext(AuthContext);
  const [packages, setPackages] = useState([]);
  // console.log(props);

  useEffect(() => {
    get_packages(authAxios).then((res) => {
      setPackages(res.data.filter(item => item.name != 'Owner' && item.name != 'Free'));
    });
  }, []);
  console.log(packages)

  return (
    <div className='homePage'>
      <Navbar />
      <main>
        <img src='/assets/imgs/hero.png' alt='hero img' />
        <div className='img_wrapper'></div>
        <div className='hero_text'>
          Quality Cars with Unlimited <br /> Miles
        </div>
      </main>
      {/* <TimingsCard props={props} /> */}
      <div className='card_wrapper'>
        <div className='card_container'>
          {packages.map(pack => <Card
            amount={pack.price}
            period='month'
            title={pack.name}
            available={pack.items.map(item => item.page)}
            features={pages}
            sname={pack.sname}
          />)}
        </div>
      </div>
      <Footer />
    </div>
  );
}
