import React from 'react';
import { useNavigate } from "react-router-dom";
import './index.css';

export default function Card({ amount, period, title, features, available, sname }) {
  const navigate = useNavigate();
  const availablePages = 
    features.map((feature) => ({...feature, available: available.includes(feature.link)}))
    .sort((a, b) =>  b.available - a.available);
  return (
    <div className='cards'>
      <div className='cards_price'>
        <p>${amount}</p> <br />
        <span>Per Month</span>
      </div>
      <div>
        <div className='cards_title'>{title}</div>
        <div className='cards_body'>
          {availablePages.map((feature, index) => (
            <div>
              <span>
                {/* {available.map(a => a === index ? ())} */}
                {feature.available ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#82f782'
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    strokeWidth={2}
                    style={{ transform: 'rotate(45deg', stroke: '#df2525' }}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                )}
              </span>
              {feature.name}
            </div>
          ))}
        </div>
      </div>
      <div className='order_btn'>
        <button onClick={() => navigate('/register/' + sname)}>Order Now</button>
      </div>
    </div>
  );
}
