export const pages = [
    { link: 'daily_overview', name: 'Daily Overview'},
    { link: 'messages', name: 'Messages'},
    { link: 'top_rented', name: 'Top Rented Cars'},
    { link: 'on_rent', name: 'On Rent'},
    { link: 'all_cars', name: 'All Cars'},
    { link: 'view_bookings', name: 'View Bookings'},
    { link: 'add_vehicle', name: 'Add Vehicle'},
    { link: 'add_booking', name: 'Add Booking'},
    { link: 'add_person', name: 'Add Person'},
    { link: 'discount_coupon', name: 'Discount Coupon'},
    { link: 'car_report', name: 'Car Report'},
    { link: 'trip_management', name: 'Trip Management'},
    { link: 'notification_history', name: 'Notification History'},
];