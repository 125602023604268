let API_URL_ = '';
let Socket_URL_ = '';
let getCompanyAdminHost_;
let getCompanyCustomerHost_;
let MAIN_HOST_ = '';
let subfix_domain_ = '';
let my_env = 'local';

if (!window.location.host.includes('localhost')) {
  if (window.location.host.includes('dev-admin') || window.location.host.includes('dev.')) {
    my_env = 'stage';
  }
  else my_env = 'production';
}

if (my_env === 'production') {
  API_URL_ = 'https://api.aviosoftware.com/';
  Socket_URL_ = 'wss://api.aviosoftware.com/';
  getCompanyAdminHost_ = (company) => {
    if (company == 'renotahoemanager' || !company) {
      return `https://admin.aviosoftware.com`;
    }
    return `https://admin.${company}.aviosoftware.com`;
  };
  getCompanyCustomerHost_ = (company) => {
    if (company == 'renotahoemanager' || !company) {
      return `https://customer.aviosoftware.com`;
    }
    return `https://${company}.aviosoftware.com`;
  };
  MAIN_HOST_ = `https://aviosoftware.com`;
  subfix_domain_ = 
    window.location.host
      .replace('aviosoftware.com', '')
      .replace('admin', '')
      .replace('customer', '')
      .replaceAll('.', '') || 'renotahoemanager';
}
else if (my_env === 'stage') {
  API_URL_ = 'https://dev-api.aviosoftware.com/';
  Socket_URL_ = 'wss://dev-api.aviosoftware.com/';
  getCompanyAdminHost_ = (company) => {
    if (company == 'renotahoemanager' || !company) {
      return `https://dev-admin.aviosoftware.com`;
    }
    return `https://dev-admin.${company}.aviosoftware.com`;
  };
  getCompanyCustomerHost_ = (company) => {
    if (company == 'renotahoemanager' || !company) {
      return `https://dev-customer.aviosoftware.com`;
    }
    return `https://dev.${company}.aviosoftware.com`;
  };
  MAIN_HOST_ = `https://dev.aviosoftware.com`;
  subfix_domain_ = 
    window.location.host
      .replace('aviosoftware.com', '')
      .replace('dev-admin', '')
      .replace('dev-customer', '')
      .replace('dev', '')
      .replaceAll('.', '') || 'renotahoemanager';
}
else {
  API_URL_ = 'http://localhost:8000/';
  Socket_URL_ = 'ws://127.0.0.1:8000/';
  getCompanyAdminHost_ = (company) => {
    return `http://${
      company && company != 'renotahoemanager' ? company + '.' : ''
    }localhost:3000`;
  };
  getCompanyCustomerHost_ = (company) => {
    return `http://${
      company && company != 'renotahoemanager' ? company + '.' : ''
    }localhost:3001`;
  };
  MAIN_HOST_ = `http://localhost:3002`;
  subfix_domain_ = 
    window.location.host
      .split(':')[0]
      .split('.')[0]
      .replace('localhost', '');
}

export const API_URL = API_URL_;
export const Socket_URL = Socket_URL_;
export const getCompanyAdminHost = getCompanyAdminHost_;
export const getCompanyCustomerHost = getCompanyCustomerHost_;
export const MAIN_HOST = MAIN_HOST_;
export const subfix_domain = subfix_domain_